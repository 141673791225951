import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0065 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="年次有給休暇管理簿とは？対象者、罰則、作成方法、保存期間などについて解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2024.07.26</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        2019年4月1日以後、企業規模を問わず、年10日以上有給休暇を付与される労働者に対して付与された日から1年以内に5日の時季指定が義務化されています。この時季指定義務と併せて「年次有給休暇管理簿」の作成も義務付けられています。今回は、年次有給休暇管理簿について、対象者、罰則、作成方法、保存期間等について解説します。{' '}
      </p>
      <img src="/images/column/details/c0065.jpg" alt="キンクラコラム画像65" />
      <h1 id="toc1">年次有給休暇管理簿とは</h1>
      <p>
        使用者は年次有給休暇を与えた場合には労働者ごとに
        <span>
          取得した時季、取得した日数、基準日、残日数や使用者が時季指定した日を明らかにし、書面を作成
        </span>
        しなければならず、
        <span>これらの内容が網羅されている書面を年次有給休暇管理簿</span>
        と言います。有給休暇の与え方については、労働者自身による請求、使用者による時季指定、計画的付与等複数の手法が想定されますが、年次有給休暇管理簿は労働者名簿または賃金台帳と併せて調製しても差し支えないとされています。
      </p>

      <h1 id="toc2">対象者</h1>
      <p>
        法律条文上は年10日以上の有給休暇を付与されている労働者に限定するということはなく、年10日未満の有給休暇付与対象者（例えば週に2日程度勤務するパートタイマー）も対象になります。年10日以上有給休暇を付与される労働者に対しては、付与された日から1年以内に5日の時季指定が達成できなければ法律上は30万円以下の罰金が科せられますので、年10日以上有給休暇を付与されている労働者に限り対象と考えられがちですが、そうではありません。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">罰則</h1>
      <p>
        年次有給休暇管理簿を調整できていないことに対する直接的な罰則はありませんが、有給休暇の5日時季指定義務に対する罰則については次の通りです。
        <span>
          付与された日から1年以内に5日の時季指定が達成できなければ法律上は30万円以下の罰金
        </span>
        となり、これは「1人1罪」と考えられ、達成できなかった労働者が複数名いる場合、その人数分だけ罰金額も増えることとなります。
      </p>

      <h1 id="toc4">作成方法</h1>
      <p>
        次の3つの選択肢が考えられます。
        <br />
        (1)手書きで作成
        <br />
        見やすさ等を優先し、最も自由な形で作成が可能というメリットがありますが、最も時間がかかると言えます。また、修正が必要な場合にも対応に苦慮することが予想されます。
        <br />
        <br />
        (2)エクセルで作成
        <br />
        (1)と同様に見やすさ等を優先し、最も自由な形で作成が可能というメリットがありますが、((1)ほどではないにせよ)作成に時間を要します。そのため、行政機関等から出されているフォーマットを活用することで時間短縮を図れます。
        <br />
        <br />
        (3)システムで作成
        <br />
        書面で調製となっていますが、システムで作成してはならないということではありません。特にクラウド型のシステムで作成することで、
        <span>
          場所を選ばずにデータにアクセスが可能となり、(1)や(2)と比べてデータの紛失リスクも少なくなると言えます。
        </span>
        ただし、導入当初は操作手順の習熟に苦慮することや比較的ITリテラシーが高くない方の場合、導入に対して懐疑的な意見が予想されます。
      </p>

      <h1 id="toc5">保存期間</h1>
      <p>
        他の帳簿類と同様に3年の保存義務があります。
        <br />
        <br />
        年次有給休暇管理簿について、対象者、罰則、作成方法、保存期間
        <br />
        有給休暇管理簿がない場合、まずは、どの労働者にどの程度有給休暇を付与しているかの把握が難しくなります。大企業のように毎年4月1日に新卒で一括採用を行う場合、採用時期が同じであるため、いつまでに付与しなければならないかは、ある程度予測が立ちますが、日本の99％を占める中小企業の場合、必ずしも毎年4月1日に限定して採用するとは限らず、中途採用も行われています。そうなると、労働者の数だけ基準日が異なりますので、いつまでに5日時季指定しなければならないのかの「デッドライン」の把握が難しくなるのは明らかです。
        <br />
        <br />
        他には有給休暇は一斉に取得されてしまうと事業の正常な運営に支障をきたす可能性が高くなるため、ある程度、部署や労働者ごとに平均的に取得してもらうことで労働者の不満や過重労働を回避することもできると考えられます。年次有給休暇管理簿がなければ、例えばどの部署では平均的にどの程度の取得がされている等の把握が難しく、いつ、どの程度取得するのかは労働者の裁量に委ねざるを得ませんので
        <span>業務が落ち着いたタイミング等、取得希望日が重複するリスク</span>
        も生じ得ます。また、
        <span>
          年次有給休暇管理簿を元に会社としても有給休暇の取得推移を把握することで1年が経過する間際に駆け込みで取得させなければ法違反となるような事態を回避できます。
        </span>
        特に基準日が4月1日の場合、1年経過時は年度末である3月31日となります。もちろん職種にもよりますが、一般的には年度末は繁忙期となることが多く、この時期に対象労者全員に対して
        <span>
          不足日数分をまとめて消化させるような労務管理ですと通常業務に多大な影響が予想され
        </span>
        ます。
      </p>

      <h1 id="toc6">最後に</h1>
      <p>
        年次有給休暇管理簿は年5日の時季指定義務違反を回避する意味でも重要なものです。特にこれから複数名の労働者を雇用していく企業の場合、労働者ごとに有給休暇発生の基準日は異なってきますので、基準日の把握が困難となってしまう前に、早期に準備を整えておくことが重要です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0065
