import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import ColumnBtnCard from '../atoms/columnBtnCard'

const Wrapper = styled.article`
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    span {
      display: none;
    }
  }
  ${BreakPoints.large} {
    margin-bottom: 10px;
  }
`

/**
 * 関連記事カードセット
 * TODO 川上申し送り事項：記事詳細下部に表示されるコンポーネントです。
 * ここでは、表示している記事に関連した他の記事3件が表示される予定です
 * @constructor
 */
const ColumnRelationCards0065 = () => (
  <Wrapper>
    <ColumnH1 label="関連コラム" />
    <section>
      <ColumnBtnCard
        href="/column/details/C0021"
        imgId="c0021.jpg"
        label="年次有給休暇とは？付与の条件や日数、時期などを詳しく解説"
        date="2022.04.15"
      />
      <ColumnBtnCard
        href="/column/details/C0053"
        imgId="c0053.jpg"
        label="年次有給休暇に関する事例集クイズ。いくつ正解できますか？"
        date="2022.06.28"
      />
    </section>
  </Wrapper>
)

export default ColumnRelationCards0065
